<template>
  <div
    class="relative flex flex-col min-h-0 overflow-hidden print:overflow-visible print:h-auto print:w-auto"
  >
    <div class="flex flex-col flex-1 min-h-0">
      <div class="flex flex-col flex-shrink-0">
        <slot name="subheader" />
      </div>
      <div class="flex flex-1 min-h-0">
        <div class="flex flex-col overflow-auto">
          <slot name="sidebar" />
        </div>
        <div class="relative flex flex-col flex-1 min-w-0">
          <div class="relative flex flex-col flex-1 overflow-auto">
            <slot name="content" />
          </div>
          <slot name="floating-content" />
        </div>
      </div>
      <div class="flex flex-col flex-shrink-0">
        <slot name="footer" />
      </div>
    </div>
    <slot name="floating" />
  </div>
</template>
