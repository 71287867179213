/**
 * Dedupes a list of invitations preserving only one per email.
 *
 * Useful for displaying purposes.
 */
export function dedupeInvitationsByEmail(invitations) {
    const emailToInvitation = new Map();
    for (const i of invitations) {
        emailToInvitation.set(i.email, i);
    }
    return Array.from(emailToInvitation.values());
}
