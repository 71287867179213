<template>
  <div class="absolute top-0 left-0 flex min-w-full min-h-full pointer-events-none">
    <div class="flex flex-col items-center justify-center flex-1 slot-wrapper">
      <slot />
    </div>
  </div>
</template>

<style>
.slot-wrapper > * {
  pointer-events: auto;
}
</style>
